<template>
<div style="padding-left: 100px; padding-right: 100px;">
    <side-bar/>
<v-container fluid >
	
<v-row>
    <v-col cols="12" sm="3">
        <h3 style="padding-bottom: 50px">Export Raw Data (.csv file)</h3>
    </v-col>
    
    <v-col  cols="12" sm="3" >
        <v-select
            v-model="tag_selected"
            :items="tag_to_select"
            label="Tags to view"
            chips
            persistent-hint
            @change="Validate()"
        ></v-select>
    </v-col>
    <v-col cols="12" sm="4" ></v-col>
    <v-col cols="12" sm="4" v-if="tag_selected.length"> durée totale d'utilisation : {{ duree_totale }}</v-col>
</v-row>

<v-row v-if="sequences.length">
    <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="sequences"
            class="elevation-1"
        >
            <template v-slot:item.start_date="{ item }">
                {{ formatDate(item.start_date) }}
            </template>
            <template v-slot:item.end_date="{ item }">
                {{ formatDate(item.end_date) }}
            </template>
            <template v-slot:item.duration="{ item }">
                {{ item.duration }}
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn style="background-color: var(--vert); border-radius: 10px; font-size: 15px;" @click="exportData(item)">Charger CSV</v-btn>
            </template>
        </v-data-table>
    </v-col>
</v-row>

<v-progress-linear v-if="isLoading" color="primary" indeterminate></v-progress-linear>

<v-dialog v-model="dialog" max-width="300">
    <v-card>
        <v-container fluid>
            <v-row>
                <v-col cols="9">
                    <v-card-title class="headline">Exporter ?</v-card-title>
                </v-col>
                <v-col cols="" class="text-right">
                    <v-btn icon color="blue darken-1" @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        
        <vue-json-to-csv :json-data="data_loaded" :labels="{
            received_on: { title: 'Date de réception' },
            acc_axe_x: { title: 'Axe X' },
            acc_axe_y: { title: 'Axe Y' },
            acc_axe_z: { title: 'Axe Z' },
            bpm: { title: 'BPM' },
            pa_diastolique: { title: 'Pression artérielle diastolique'},
            pa_systolique: { title: 'Pression artérielle systolique' },
            spo2: { title: 'Taux d\'oxygène' },
            temperature: { title: 'Température' }
        }" :csv-title="'My_CSV'" class="row-center">
            <v-btn style="background-color: var(--vert); border-radius: 10px; font-size: 15px; margin: 20px 0;"> EXPORT CSV </v-btn>
        </vue-json-to-csv>
    </v-card>
</v-dialog>

  
</v-container>
</div> 
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import moment from 'moment';
import SideBar from '../components/SideBar.vue';
moment.locale('fr');


export default {
	name: "json",
    components: { 
	VueJsonToCsv,
    SideBar,       
		},
    data () {
        return {
//***************** Data formulaire *********************//
            tag_to_select: [],
            tag_selected: [],
            tag_and_mat_selected: [],
            sequences: [],
            duree_totale: '',
            dialog: false,
            data_loaded: [],
            headers: [
                { text: 'Date de début', value: 'start_date' },
                { text: 'Date de fin', value: 'end_date' },
                { text: 'Durée en minutes', value: 'formatted_duration' },
                { text: 'Actions', value: 'action', sortable: false }
            ],
            isLoading: false,
	    }
},

computed: {
      dateRangeText () {
        return this.date + ' à ' + this.time1 + ' ~ ' +  this.date1 + ' à ' + this.time2;
      },
},

mounted(){
	this.getTagAvailable();
},

methods: {

async exportData(item){
    this.isLoading = true;
    const tag_selected = this.tag_id[this.tag_to_select.indexOf(this.tag_selected)]
    const tag_id = encodeURIComponent(tag_selected);
    const data_select = encodeURIComponent(this.data_selected);
    const date_1 = encodeURIComponent(item.start_date);
    const date_2 = encodeURIComponent(item.end_date);
    console.log(item.start_date + '     ' + item.end_date)
    console.log(tag_id)
    const url = this.$api.getRESTApiUri() + `/export/table-name/${tag_id}/${data_select}/date_debut/${date_1}/date_fin/${date_2}`;
    console.log(url)  
    fetch(url)
    .then(res => res.text())
    .then((result) => {
        this.data_loaded  = JSON.parse(result);
        console.log(this.data_loaded);
        this.dialog = true;
    })
    .catch((error) => {
        console.log(error)
    })
    .finally(() => {
        this.isLoading = false;
    });
},

//récupère les tags attribués au compte connecté et les envoie dans le formulaire
async getTagAvailable(){
    const user = JSON.parse(localStorage.getItem('user'))
    this.email = user.email
    const email = encodeURIComponent(this.email);
    const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
    return fetch(url)
    .then(res => res.text())
    .then(async (result) => {
        const data = JSON.parse(result);
        
        this.tag_id = data.tag_attributed
        this.tag_name = data.tag_renamed
        for(let i=0 ; i< this.tag_id.length ; i++){
        if(this.tag_name[i] == "noname"){
            this.tag_to_select.push(this.tag_id[i])
        }else{
            this.tag_to_select.push(this.tag_name[i])
        }
    }
    })
    .catch(error => {
        console.log(error)
    });
},

async Validate(){
    this.tag_and_mat_selected = [];
    console.log(this.tag_selected)
    console.log(this.tag_id)
    this.tag_and_mat_selected.push(this.tag_id[this.tag_to_select.indexOf(this.tag_selected)])
    this.show = 1;
    console.log(this.tag_and_mat_selected)
    await this.fetchSequences();
},

async fetchSequences() {
    const tag_id = encodeURIComponent(this.tag_and_mat_selected);
    const url = this.$api.getRESTApiUri() + `/data-transmission-duration/${tag_id}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        let total_duration = 0;
        data.forEach(item => {
            total_duration += item.duration;
            item.formatted_duration = this.formatDuration2(item.duration);
        });
        this.duree_totale = this.formatDuration(total_duration)
        console.log(data[data.length-1].start_date)
        console.log(this.formatDate(data[data.length-1].start_date))
        // Trier les séquences du plus récent au moins récent
        data.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        this.sequences = data;
    } catch (error) {
        console.error("Error fetching sequences:", error);
    }
},


formatDate(date) {
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth() + 1; // Note: Months are 0-based, so we add 1.
    const day = dateObject.getUTCDate();
    const hours = dateObject.getUTCHours();
    const minutes = dateObject.getUTCMinutes();
    const seconds = dateObject.getUTCSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
},

formatDuration(seconds) {
    const days = Math.floor(seconds / (24 * 3600));
    seconds -= days * 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${days}J ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${Math.floor(seconds).toString().padStart(2, '0')}`;
},

formatDuration2(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);

    return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m`;
},
}
}
</script>